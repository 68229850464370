<template>
  <div>
    <div class="row justify-content-center">
      <!-- 流程圖 -->
      <div class="col-md-8 my-md-5">
        <div class="p-3 p-md-0">
          <div class="position-relative m-4">
            <div class="progress bg-gray" style="height: 4px;">
              <div
                class="progress-bar bg-gray"
                role="progressbar"
                style="width: 50%;"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <button
              type="button"
              class="position-absolute top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill fs-10 fs-sm-5 fs-md-4 text-light step"
              style="width: 3rem; height:3rem;"
            >
              1
              <span
                class="fw-normal text-dark position-absolute text-nowrap step1"
                >購物車</span
              >
            </button>
            <button
              type="button"
              class="position-absolute top-0 start-50 translate-middle btn btn-sm btn-secondary rounded-pill fs-10 fs-sm-5 fs-md-4 text-light step"
              style="width: 3rem; height:3rem;"
            >
              2
              <span
                class="fw-normal text-dark position-absolute text-nowrap step2"
                >填寫資料</span
              >
            </button>
            <button
              type="button"
              class="position-absolute top-0 start-100 translate-middle btn btn-sm btn-secondary rounded-pill fs-10 fs-sm-5 fs-md-4 step text-light"
              style="width: 3rem; height:3rem;"
            >
              3
              <span
                class="fw-normal text-dark position-absolute text-nowrap step3"
                >訂單確認</span
              >
            </button>
          </div>
        </div>
      </div>
      <div class="my-5">
        <!-- 購物車 -->
        <div class="bg-white mb-4">
          <h4 class="px-3 px-md-4 py-3 border-bottom border-dark border-2">購物車 ( {{ originSum }} 件)</h4>
          <div v-if="!cartList || cartList.Products.length === 0" class="pt-6 bg-white" style="height:280px">
            <h3 class="text-center">目前購物車內無任何商品</h3>
          </div>
          <table v-if="originList && originList.length > 0" class="table table-borderless mb-0">
            <thead>
              <tr class="border-bottom d-none d-lg-table-row">
                <th colspan="2" scope="col" width="55%" class="ps-md-5 py-md-3">商品資料</th>
                <th scope="col" class="py-md-3 text-center">單價</th>
                <th scope="col" class="py-md-3 text-center" width="20%">數量</th>
                <th scope="col" class="py-md-3 text-center">小計</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="product in originList" :key="product+'1'">
                <tr class="cartBorderBottom"  :class="{ 'addon-error': unionCarRentalAddOnError.includes(product.Id) }">
                  <td class="ps-3 ps-lg-5 pb-0 pt-2 pt-lg-3 pb-lg-3 w-30 w-lg-15">
                    <img class="img-fluid" :src="product.ImageUrl" alt="product image">
                  </td>
                  <td class="py-md-3">
                    <!-- <span v-if="product.addbuy" class="d-inline-block bg-primary fw-light text-white px-3 mb-2">加購品</span> -->
                    <router-link :to="`/product/${product.Id}`" class="d-block">
                      <p>{{ product.Name }}</p>
                      <div class="d-flex align-items-center mt-2" v-if="product.Gift">
                        <p class="fs-7 px-1 bg-primary text-white me-1">贈品</p>
                        <small class="text-muted">{{ product.Gift.Name }}</small>
                      </div>
                    </router-link>
                  </td>
                  <td class="py-md-3 d-none d-lg-table-cell text-center px-0 px-xl-2">$ {{ $currency.currency(product.Price) }}</td>
                  <td class="py-md-3 d-none d-lg-table-cell">
                    <div class="productNumber input-group">
                      <button
                        type="button"
                        class="btn border-dark rounded-0"
                        @click.prevent="minusNum(product)"
                      >
                        -
                      </button>
                      <input
                        type="number"
                        class="form-control border-dark text-center"
                        aria-label="product number"
                        min="1"
                        onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                        v-model.number="product.Quantity"
                        @change="numInput(product)"
                      />
                      <button type="button" class="btn border-dark rounded-0" @click.prevent="addNum(product)">
                        +
                      </button>
                    </div>
                  </td>
                  <td class="py-md-3 d-none d-lg-table-cell text-center">$ {{ $currency.currency(product.Price * product.Quantity) }}</td>
                  <td class="py-md-3 text-center">
                    <button
                      type="button"
                      class="btn fs-5"
                      @click.prevent="deleteProduct(product)"
                    >
                      <i class="far fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
                <tr class="d-lg-none">
                  <td colspan="6" class="text-end fw-light px-3 pt-0 pb-2">$ {{ $currency.currency(product.Price) }}</td>
                </tr>
                <tr class="border-bottom d-lg-none">
                  <td colspan="6" class="px-3 pb-3 pt-0">
                    <div class="d-flex justify-content-end">
                      <div class="productNumber input-group w-45">
                        <button
                          type="button"
                          class="btn border-dark rounded-0"
                          @click.prevent="minusNum(product)"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          class="form-control border-dark text-center py-1"
                          aria-label="product number"
                          min="1"
                          onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                          v-model.number="product.Quantity"
                          @change="numInput(product)"
                        />
                        <button type="button" class="btn border-dark rounded-0" @click.prevent="addNum(product)">
                          +
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="text-end p-3">
            <router-link to="/productlist" class="text-decoration-underline">繼續購物</router-link>
          </div>
        </div>
        <template v-if="unionCarRentalAddOnError.length===0">
           <!-- 優惠專區 -->
        <div class="bg-white mb-5" v-if="eventList.length > 0">
          <EventCartList :event-list='eventList' @update-all-cart="updateAllCart" @check-event-cart="checkEventCart"></EventCartList>
          <div class="text-end p-3">
            <router-link to="/double11Event" class="text-decoration-underline">繼續購物</router-link>
          </div>
        </div>
        <!-- 加價購專區 -->
        <!-- <div v-if="cartList && cartList.Products.length !== 0" class="bg-white position-relative mb-4">
          <h4 class="px-3 px-md-4 py-3 border-bottom border-dark border-2">加價購專區</h4>
          <div class="relativeProducts py-4">
            <swiper
              :slidesPerView="2.2"
              :spaceBetween="10"
              :scrollbar="{
                el: '.swiper-scrollbar',
                hide: true
              }"
              :mousewheel="true"
              class="relativeProductSwiper"
              :navigation="{
                nextEl: '#js-next1',
                prevEl: '#js-prev1'
              }"
              :breakpoints="{
                992: {
                  slidesPerView: 3.3
                },
                1024: {
                  slidesPerView: 4
                }
              }"
            >
              <swiper-slide>
                <div class="card card-hover rounded-0 text-dark">
                  <img
                    src="https://www.papafresh.com.tw/Pic/list/PA037.jpg?rdn=916099651"
                    class="card-img-top"
                  />
                  <div class="card-body p-1">
                    <h5 class="card-title title-height fw-light fs-6 fs-xl-5">
                      木瓜酵素潔顏粉_保濕型
                    </h5>
                    <p class="text-danger ms-2 ms-md-0 ms-lg-2 fs-6 fs-lg-5 mb-2">NT$723</p>
                    <div class="d-grid gap-2">
                      <button type="button" class="btn btn-primary rounded-0" @click.prevent="addToCart">優惠加購</button>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>

            <div id="js-prev1" class="swiper-button-prev d-none d-md-flex"></div>
            <div id="js-next1" class="swiper-button-next d-none d-md-flex"></div>
            <div class="swiper-scrollbar d-md-none"></div>
          </div>
        </div> -->
        <!-- 會員優惠折抵 -->
        <div v-if="cartList && cartList.Products.length !== 0" class="bg-white mb-4">
          <h4 class="px-3 px-md-4 py-3 border-bottom border-dark border-2">
            會員優惠折抵
            <span v-if="cartList.Activities.length > 0" class="text-danger fs-7 fs-xl-6 d-block d-xl-inline ms-xl-2 mt-2">※ 僅可擇一使用</span>
          </h4>
          <div class="bg-white px-2 py-3 py-md-4 px-md-5">
            <!-- 點數折抵 -->
            <div class="d-flex">
              <div class="input-item me-3">
                <input id="rewards" class="form-check-input" type="radio" value="rewards" name="discount" v-model="discountSelect" @click="changeDiscount('rewards')" :disabled="cartList.RewardPoint?.CanUse === 0">
              </div>
              <div class="flex-grow-1">
                <label for="rewards">
                  <h5 class="fs-6 mb-0 mb-md-2"><span class="text-primary">點數折抵 : 本次可使用 {{ cartList.RewardPoint.CanUse }} 點</span>(目前共有 {{ cartList.RewardPoint.Total }} 點)</h5>
                  <p class="mb-3">本次剩餘 {{ $currency.currency(cartList.RewardPoint.CanUse - rewards) }} 元可使用</p>
                </label>
                <div>
                  本次使用
                  <input @change="useRewards" v-model="rewards" type="number" ref="rewardsInputDom" class="mx-2 w-60 w-sm-70 w-md-25 text-end"  :disabled="cartList.RewardPoint?.CanUse === 0"/>
                  元
                </div>
              </div>
            </div>
            <!-- 滿額折 -->
            <template v-if="cartList.Activities.CanUse.length > 0">
              <div class="d-flex border-top mt-3 pt-3" v-for="act in cartList.Activities.CanUse" :key="act.Id">
                <div class="input-item me-3">
                  <input :id="`discount${act.Id}`" class="form-check-input" type="radio" name="discount" :value="`event${act.Id}`" v-model="discountSelect" :disabled="!act.IsConform" @click="changeDiscount(`event${act.Id}`, act)">
                </div>
                <div class="flex-grow-1">
                  <label :for="`discount${act.Id}`" class="text-primary fs-6 mb-0 mb-md-2">滿額折：{{ act.Name }}
                  </label>
                  <span v-if="act.IsConform" class="text-success ms-lg-2">( O ) 已符合優惠</span>
                  <span v-else class="text-danger ms-lg-2">( ! ) 未符合 差 {{ act.Difference }} 元享優惠</span>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- 訂單資訊 -->
        <div v-if="cartList && cartList.Products.length !== 0" class="bg-white mb-5">
          <h4 class="px-3 px-md-4 py-3 border-bottom border-dark border-2">訂單資訊</h4>
          <div class="border-bottom border-dark border-1">
            <div class="d-flex justify-content-between px-3 pt-3 pb-2 px-md-5">
              <p>商品總金額</p>
              <p v-if="isSameQuantity">$ {{ $currency.currency(cartList.AmountResult.PayableAmount) }}</p>
              <p v-else>- -</p>
            </div>
            <div v-if="discountSelect.includes('event')" class="d-flex justify-content-between px-3 py-2 px-md-5">
              <p>活動折扣</p>
              <p>$ {{ cartList.AmountResult.ActivityDiscount }}</p>
            </div>
            <div v-if="discountSelect === 'rewards' && cartList.AmountResult.PointDiscount !== 0" class="d-flex justify-content-between px-3 py-2 px-md-5">
              <p>點數折抵</p>
              <p>$ {{ cartList.AmountResult.PointDiscount ? $currency.currency(cartList.AmountResult.PointDiscount) : 0 }}</p>
            </div>
            <div v-if="this.cartList.OrderGifts.length > 0">
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center px-3 px-md-5 pt-2 pb-3">
                  <p class="fs-7 px-1 bg-primary text-white me-2">滿額贈</p>
                  <small class="text-muted">{{ this.cartList.OrderGifts[0].Name }}</small>
                </div>
              </div>
            </div>
            <!-- 折扣優惠 -->
            <!-- <div class="d-flex justify-content-between px-3 pb-3 pt-2 px-md-5">
              <p>折扣優惠</p>
              <p>-$ 100</p>
            </div> -->
          </div>
          <div class="d-flex justify-content-between px-3 py-3 px-md-5">
            <p>合計( {{ this.$store.state.cartNum }} 件)</p>
            <p v-if="isSameQuantity" class="text-primary">$ {{ $currency.currency(cartList.AmountResult.PaidAmount) }}</p>
            <p v-else>- -</p>
          </div>
        </div>
        <!-- 付款方式及紅利折抵 -->
        <div v-if="cartList && cartList.Products.length !== 0" class="bg-white mb-4">
          <h4 class="px-3 px-md-4 py-3 border-bottom border-dark border-2">
            選擇付款方式及紅利折抵
            <span class="fs-7 fs-xl-6 d-block d-xl-inline ms-xl-2 mt-2">※若您有聯邦信用卡紅利點數但未顯示，請您參考聯邦紅利卡友兌換說明</span>
          </h4>
          <template v-for="payment in cartList.PaymentMethods.CanUse" :key="payment+'2'">
            <div class="bg-white px-2 py-3 px-md-5 border-bottom" v-if="cartList.AmountResult.PaidAmount >= payment.LimitPrice">
              <div class="form-check">
                <input class="form-check-input me-2 me-md-3" type="radio" name="paymentRadio" :id="payment.Code" :value="payment.Code" v-model="paymentMethod" @change="usePaymentMethod"/>
                <label class="form-check-label" :for="payment.Code">{{ payment.Name }}</label>
              </div>
            </div>
          </template>
        </div>
        <!-- 到第二頁 填寫資料 -->
        <div v-if="cartList && cartList.Products.length !== 0" class="row justify-content-center">
          <div class="col-lg-6">
            <div class="d-grid gap-2">
              <button class="btn btn-primary rounded-0 fs-6 fs-lg-5" type="button" @click.prevent="toCheckoutInfo">確定</button>
            </div>
          </div>
        </div>
        </template>
      </div>
    </div>
    <!-- loading 動畫 -->
    <loading :active="isLoading" class="bottom-fixed position-fixed top-50 start-50 translate-middle" id="overlay" backgroundColor='transparent' blur="">
      <div class="loading1">
        <div class="demo1"></div>
        <div class="demo1"></div>
        <div class="demo1"></div>
        <div class="demo1"></div>
        <div class="demo1"></div>
      </div>
    </loading>
  </div>
</template>

<script>
import CartSvc from '@/service/cart-service.js'
import EventCartList from '@/components/EventCartList.vue'

export default {
  components: {
    EventCartList
  },
  data () {
    return {
      cartList: '',
      originList: [],
      eventList: [], // 雙 11 活動專區商品後的 A + B)
      isLoading: true,
      paymentMethod: '', // 付款方式
      postToInfo: { // 購物車資訊
        payInfo: {},
        items: {},
        orderGifts: [],
        activities: []
      },
      rewards: 0, // 點數折抵
      discountSelect: '',
      tempEventCart: [],
      unionCarRentalAddOnError: [], // 租車加購錯誤商品
      isFromUnionCarRental: false // 從租車來?
    }
  },
  methods: {
    //* 取得購物車
    getCheckoutCart () {
      CartSvc.GetCart().then((data) => {
        this.isLoading = false
        const storedValue = sessionStorage.getItem('store')
        if (Object.keys(data).length !== 0 && data.isSuccess === false) {
          // (5030)聯邦租車加購錯誤
          if (data.rtnCode === 5030) {
            if (storedValue) {
              this.unionCarRentalAddOnError = data.errProducts
              this.showFurtherDetail = false
              this.$swal.fire({
                html: '購物車只有加價購商品!<br>請再加把勁選購其他商品，<br>才能享有加購優惠活動喔!',
                allowOutsideClick: false,
                confirmButtonColor: '#003894',
                confirmButtonText: '繼續購物',
                showCloseButton: true,
                width: 380,
                customClass: {
                  title: 'text-class',
                  confirmButton: 'confirm-btn-class'
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push('/event/unionrentalcar')
                }
              })
            } else {
              this.unionCarRentalAddOnError = data.errProducts
              this.$swal.fire({
                html: '購物車異常，請重新選購',
                allowOutsideClick: false,
                confirmButtonColor: '#003894',
                confirmButtonText: '確定',
                width: 380,
                customClass: {
                  title: 'text-class',
                  confirmButton: 'confirm-btn-class'
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  CartSvc.RemoveAllFromCart()
                  this.$router.push('/')
                  CartSvc.GetCart()
                }
              })
            }
          } else {
            this.$swal.fire({
              title: `${data.msg}`,
              allowOutsideClick: true,
              confirmButtonColor: '#003894',
              confirmButtonText: '確認',
              width: 400,
              customClass: {
                title: 'text-class',
                confirmButton: 'confirm-btn-class'
              }
            })
          }

          this.originList = []
          this.eventList = []
          this.cartList = data.data
          this.getDividCart() // * 拆分購物車(origin 、 event)
          this.updateUsed()
        } else {
          this.cartList = data
          // *===行動裝置才顯示ipass pay
          const width = window.innerWidth
          if (width > 430) {
            this.cartList.PaymentMethods.CanUse = this.cartList.PaymentMethods.CanUse.filter(method => method.Code !== 'P11')
          }
          if (this.isFromUnionCarRental) {
            // 租車付款排除 ATM轉帳&貨到付款
            this.cartList.PaymentMethods.CanUse = this.cartList.PaymentMethods.CanUse.filter(method => method.Code !== 'P10' && method.Code !== 'P05')
          }
          this.getDividCart() // * 拆分購物車(origin 、 event)
          this.checkLocalStorage()
          this.updateUsed()
          if (this.cartList) {
            this.rewards = this.cartList.RewardPoint.Used ? this.cartList.RewardPoint.Used : 0
          }
        }
      })
    },
    // * 拆分購物車(origin 、 event)
    getDividCart () {
      if (!this.cartList) {
        return
      }
      const width = window.innerWidth
      if (width > 430) {
        this.cartList.PaymentMethods.CanUse = this.cartList.PaymentMethods.CanUse.filter(method => method.Code !== 'P11')
      }
      this.originList = []
      this.eventList = []
      this.originList = this.cartList.Products.filter((item) => item.Type !== 'A' && item.Type !== 'B')
      // *雙 11 活動專區
      this.eventList = this.cartList.Products.filter((item) => item.Type === 'A' || item.Type === 'B')
    },
    // * 更新購物車(remove all & add all)
    async updateAllCart (list) {
      this.isLoading = true
      const allCart = [...this.originList, ...list]
      this.eventList = list
      this.isLoading = false
      // *remove all
      const resRemove = await CartSvc.RemoveAllFromCart()
      if (resRemove.isSuccess) {
        // *add all
        if (allCart.length === 0) {
          // *重新取得購物車
          this.getCheckoutCart()
          return
        }
        const resAdd = await CartSvc.AddMultipleToCart(allCart)
        if (resAdd.isSuccess) {
          // *重新取得購物車
          this.getCheckoutCart()
        } else {
          this.isLoading = false
          this.$swal.fire({
            title: `${resAdd.msg}`,
            allowOutsideClick: true,
            confirmButtonColor: '#003894',
            confirmButtonText: '確認',
            width: 400,
            customClass: {
              title: 'text-class',
              confirmButton: 'confirm-btn-class'
            }
          })
        }
      } else {
        this.isLoading = false
        this.$swal.fire({
          title: `${resRemove.msg}`,
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
      }
    },
    // * 暫存活動購物車(eventCartList)內容
    checkEventCart (list) {
      this.tempEventCart = list
    },
    //* 商品數量減少
    minusNum (pd) {
      pd.Quantity--
      if (pd.Quantity < 1) {
        pd.Quantity = 1
        this.deleteProduct(pd)
      } else {
        this.updateCartNum(pd)
      }
    },
    //* 商品數量增加
    addNum (pd) {
      pd.Quantity++
      if (pd.Quantity > pd.Stock) {
        pd.Quantity = pd.Stock
      }

      this.updateCartNum(pd)
    },
    //* 商品 input 輸入，數量不可輸入空值、0、非正整數
    numInput (pd) {
      const rule = /^[0-9]*[1-9][0-9]*$/
      if (pd.Quantity === '') {
        pd.Quantity = 1
      } else if (pd.Quantity > pd.Stock) {
        pd.Quantity = pd.Stock
      } else if (pd.Quantity === 0) {
        pd.Quantity = 1
      } else if (!rule.test(pd.Quantity)) {
        pd.Quantity = 1
      }
      // this.rewards = 0
      // this.discountSelect = ''
      this.updateCartNum(pd)
    },
    //* 商品數量修改
    updateCartNum (pd) {
      const updateData = {
        productId: pd.Id,
        optionId: pd.OptionId,
        quantity: pd.Quantity
      }
      this.isLoading = true
      CartSvc.UpdateCart(updateData).then(data => {
        this.isLoading = false
        if (Object.keys(data).length !== 0 && data.isSuccess === false) {
          this.$swal.fire({
            title: `${data.msg}`,
            allowOutsideClick: true,
            confirmButtonColor: '#003894',
            confirmButtonText: '確認',
            width: 400,
            customClass: {
              title: 'text-class',
              confirmButton: 'confirm-btn-class'
            }
          })
          this.cartList = data.data
          this.getDividCart()
          this.updateUsed()
          return
        }
        this.cartList = data
        this.getDividCart()
        this.updateUsed()
      })
    },
    //* 商品移出購物車
    deleteProduct (pd) {
      this.$swal.fire({
        title: '刪除產品',
        text: '您確定要刪除?',
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        cancelButtonColor: '#4D4D4D',
        confirmButtonText: '確定刪除',
        cancelButtonText: '取消',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.rewards = 0
          this.discountSelect = ''
          const deleteProduct = {
            productId: pd.Id,
            optionId: pd.OptionId
          }
          this.isLoading = true
          CartSvc.RemoveFromCart(deleteProduct).then(data => {
            this.isLoading = false
            if (Object.keys(data).length !== 0 && data.isSuccess === false) {
              if (data.rtnCode === 5030) {
                this.unionCarRentalAddOnError = data.errProducts
                this.$swal.fire({
                  html: '購物車只有加價購商品!<br>請再加把勁選購其他商品，<br>才能享有加購優惠活動喔!',
                  allowOutsideClick: false,
                  confirmButtonColor: '#003894',
                  confirmButtonText: '繼續購物',
                  showCloseButton: true,
                  width: 380,
                  customClass: {
                    title: 'text-class',
                    confirmButton: 'confirm-btn-class'
                  }
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.push('/event/unionrentalcar')
                  }
                })
              } else {
                this.$swal.fire({
                  title: `${data.msg}`,
                  allowOutsideClick: true,
                  confirmButtonColor: '#003894',
                  confirmButtonText: '確認',
                  width: 400,
                  customClass: {
                    title: 'text-class',
                    confirmButton: 'confirm-btn-class'
                  }
                })
              }
              this.originList = []
              this.eventList = []
              this.cartList = data.data
              this.getDividCart()
              this.updateUsed()
              return
            }
            this.cartList = data
            this.getDividCart()
            this.updateUsed()
            this.GA_remove_from_cart(pd)
          })
        }
      })
    },
    //* 商品移出購物車
    checkLocalStorage () {
      if (localStorage.getItem('cartStatus2')) {
        this.$swal.fire({
          title: '您已達該活動品限購數量上限',
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
      }
      localStorage.setItem('cartStatus2', '')
    },
    // *點數折抵
    useRewards () {
      this.discountSelect = 'rewards'
      const rule = /^[0-9]*[1-9][0-9]*$/
      if (this.rewards > this.cartList.RewardPoint.CanUse) {
        this.rewards = this.cartList.RewardPoint.CanUse
      } else if (this.rewards === '' || !rule.test(this.rewards)) {
        this.rewards = 0
      }

      if (this.rewards === 0) {
        this.discountSelect = ''
      }
      const cartInfo = this.$store.state.cartCalculate
      cartInfo.useRewardPoint = this.rewards
      if (this.rewards !== 0) {
        cartInfo.useActivities = []
      }
      this.$store.commit('getCartCalculate', cartInfo)
      this.isLoading = true
      CartSvc.CartCalculate().then(data => {
        this.isLoading = false
        if (Object.keys(data).length !== 0 && data.isSuccess === false) {
          this.$swal.fire({
            title: `${data.msg}`,
            allowOutsideClick: true,
            confirmButtonColor: '#003894',
            confirmButtonText: '確認',
            width: 400,
            customClass: {
              title: 'text-class',
              confirmButton: 'confirm-btn-class'
            }
          })
          this.cartList = data.data
          this.getDividCart()
          this.updateUsed()
          return
        }
        this.cartList = data
        this.getDividCart()
        this.updateUsed()
      })
    },
    // *付款方式
    usePaymentMethod () {
      const pay = this.$store.state.cartCalculate
      pay.usePaymentMethod = this.paymentMethod

      this.$store.commit('getCartCalculate', pay)
      this.isLoading = true
      CartSvc.CartCalculate().then(data => {
        this.isLoading = false
        if (Object.keys(data).length !== 0 && data.isSuccess === false) {
          this.$swal.fire({
            title: `${data.msg}`,
            allowOutsideClick: true,
            confirmButtonColor: '#003894',
            confirmButtonText: '確認',
            width: 400,
            customClass: {
              title: 'text-class',
              confirmButton: 'confirm-btn-class'
            }
          })
          this.cartList = data.data
          this.getDividCart()
          this.updateUsed()
          return
        }
        this.cartList = data
        // *===行動裝置才顯示ipass pay
        const width = window.innerWidth
        if (width > 430) {
          this.cartList.PaymentMethods.CanUse = this.cartList.PaymentMethods.CanUse.filter(method => method.Code !== 'P11')
        }
        if (this.isFromUnionCarRental) {
          // 租車付款排除 ATM轉帳&貨到付款
          this.cartList.PaymentMethods.CanUse = this.cartList.PaymentMethods.CanUse.filter(method => method.Code !== 'P10' && method.Code !== 'P05')
        }
        this.getDividCart()
        this.updateUsed()
      })
    },
    // *更新已使用狀態(介面)
    updateUsed () {
      this.rewards = this.$store.state.cartCalculate.useRewardPoint
      this.discountSelect = this.$store.state.cartCalculate.useActivities.length > 0 ? `event${this.$store.state.cartCalculate.useActivities[0]}` : (this.rewards > 0 ? 'rewards' : '')
      this.paymentMethod = this.$store.state.cartCalculate.usePaymentMethod
    },
    // *會員優惠折抵 ※ 僅可擇一使用
    changeDiscount (type, act) {
      this.discountSelect = type === this.discountSelect ? '' : type
      const cartInfo = this.$store.state.cartCalculate

      // *選擇非點數 --> 清空點數值
      if (this.discountSelect !== 'rewards') {
        this.rewards = 0
        cartInfo.useRewardPoint = this.rewards
      }
      // *選擇點數
      if (this.discountSelect === 'rewards') {
        this.rewards = this.cartList.RewardPoint.CanUse
        cartInfo.useRewardPoint = this.rewards
      }

      // *選擇非活動 --> 清空活動
      if (!this.discountSelect.includes('event')) {
        cartInfo.useActivities = []
      }
      // *選擇活動
      if (this.discountSelect.includes('event')) {
        cartInfo.useActivities = [act.Id]
      }

      this.$store.commit('getCartCalculate', cartInfo)
      this.isLoading = true
      CartSvc.CartCalculate().then(data => {
        this.isLoading = false
        if (Object.keys(data).length !== 0 && data.isSuccess === false) {
          this.$swal.fire({
            title: `${data.msg}`,
            allowOutsideClick: true,
            confirmButtonColor: '#003894',
            confirmButtonText: '確認',
            width: 400,
            customClass: {
              title: 'text-class',
              confirmButton: 'confirm-btn-class'
            }
          })
          this.cartList = data.data
          this.getDividCart()
          this.updateUsed()
          return
        }
        this.cartList = data
        this.getDividCart()
        this.updateUsed()
      })
    },
    // *前往下一頁確認訂單
    toCheckoutInfo () {
      if (!this.paymentMethod) {
        this.$swal.fire({
          title: '請選擇付款方式',
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
        return
      }
      // * 檢查活動購物車
      if (this.tempEventCart.length > 0) {
        const aPrd = this.tempEventCart.filter(item => item.Type === 'A')
        const sumA = aPrd.reduce(
          (previousValue, currentValue) => previousValue + currentValue.Quantity, 0
        )
        const bPrd = this.tempEventCart.filter(item => item.Type === 'B')
        const sumB = bPrd.reduce(
          (previousValue, currentValue) => previousValue + currentValue.Quantity, 0
        )

        if (sumA !== sumB) {
          this.$swal.fire({
            title: '優惠加購品數量需與主商品數量一致',
            allowOutsideClick: true,
            confirmButtonColor: '#003894',
            confirmButtonText: '確認',
            width: 400,
            customClass: {
              title: 'text-class',
              confirmButton: 'confirm-btn-class'
            }
          })
          return
        }
      }
      // *info
      this.postToInfo.payInfo.paymentMethod = this.cartList.PaymentMethods.Used // 付款方式
      // *折抵金額到 0
      // if (this.cartList.AmountResult.PaidAmount === 0) {
      //   this.postToInfo.payInfo.paymentMethod = 'P99'
      // }
      this.postToInfo.payInfo.pointDiscount = this.cartList.AmountResult.PointDiscount // 點數折抵
      this.postToInfo.payInfo.paidAmount = this.cartList.AmountResult.PaidAmount // 結帳總金額
      this.postToInfo.payInfo.rewardMoney = 0 // 福利金
      this.postToInfo.payInfo.couponId = null // 折價券
      // *下單滿額贈品，實付金額是否大於贈送門檻
      this.postToInfo.orderGifts = []
      if (this.cartList.OrderGifts.length > 0 && this.cartList.AmountResult.PaidAmount >= this.cartList.OrderGifts[0].LimitPrice) {
        this.postToInfo.orderGifts.push(this.cartList.OrderGifts[0].Id)
      } else {
        this.postToInfo.orderGifts = null
      }

      // *items
      this.postToInfo.items = JSON.parse(JSON.stringify(this.cartList.Products)) // 購物車商品
      this.postToInfo.items.forEach((item) => {
        delete item.ImageUrl
        delete item.Name
        delete item.Stock
        delete item.OptionName
        if (!item.Gift) { // 購物車商品的贈品
          item.giftId = null
        } else {
          item.giftId = item.Gift.Id
        }
        delete item.Gift
        item.productId = item.Id
        item.optionId = item.OptionId
        item.price = item.Price
        item.quantity = item.Quantity
        delete item.Id
        delete item.OptionId
        delete item.Price
        delete item.Quantity
      })
      // * 滿額折
      if (this.cartList.Activities.Used.length > 0 && this.discountSelect.includes('event')) {
        this.postToInfo.activities.push(this.cartList.Activities.Used[0])
      }

      this.GA_begin_checkout() // * GA追蹤
      this.fbq_checkout()
      this.$store.commit('getCheckoutCartInfo', this.postToInfo)
      this.$router.push('/checkoutboard/checkoutinfo')
    },
    checkCookie () {
      const AccessToken = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
      const RefreshToken = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaRefreshToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
      if (!AccessToken && !RefreshToken) {
        this.$router.push('/')
      }
    },
    GA_remove_from_cart (pd) {
      const dataForGA = {
        items: [{}]
      }
      // !處理資料
      dataForGA.items[0] = JSON.parse(JSON.stringify(pd))
      dataForGA.items[0].item_id = dataForGA.items[0].Id // ?產品代碼
      dataForGA.items[0].item_name = dataForGA.items[0].Name // ?產品名稱
      dataForGA.items[0].price = dataForGA.items[0].Price // ?末端售價
      dataForGA.items[0].quantity = dataForGA.items[0].Quantity // ?庫存
      delete dataForGA.items[0].ImageUrl
      delete dataForGA.items[0].Id
      delete dataForGA.items[0].Name
      delete dataForGA.items[0].OptionId
      delete dataForGA.items[0].OptionName
      delete dataForGA.items[0].Gift
      delete dataForGA.items[0].Price
      delete dataForGA.items[0].Quantity
      delete dataForGA.items[0].Stock
      // !設定事件
      this.$gtag.event('remove_from_cart', dataForGA)
    },
    GA_begin_checkout () {
      const dataForGA = {
        currency: 'TWD',
        value: this.finalPrice, // ? 總金額
        items: [{}]
      }
      dataForGA.items = JSON.parse(JSON.stringify(this.cartList.Products))
      dataForGA.items.forEach(item => {
        item.item_id = item.productId // ?產品代碼
        item.item_name = item.Name // ?產品名稱
        item.price = item.Price // ?末端售價
        delete item.productId
        delete item.Name
        delete item.Stock
        delete item.Price
        delete item.optionId
        delete item.giftId
      })
      // !設定事件
      this.$gtag.event('begin_checkout', dataForGA)
    },
    // *FBQ追蹤碼(開始結帳)
    fbq_checkout () {
      const toFbqData = {
        contents: [],
        content_type: 'product',
        value: this.finalPrice,
        currency: 'TWD'
      }
      toFbqData.contents = JSON.parse(JSON.stringify(this.cartList.Products))
      toFbqData.contents.forEach((item) => {
        item.id = item.Id
        item.quantity = item.Quantity
        delete item.Gift
        delete item.Id
        delete item.ImageUrl
        delete item.Name
        delete item.OptionId
        delete item.OptionName
        delete item.Price
        delete item.Quantity
        delete item.Stock
      })
      window.fbq('track', 'InitiateCheckout', toFbqData)
    }
  },
  computed: {
    isSameQuantity () {
      const aPrd = this.tempEventCart.filter(item => item.Type === 'A')
      const sumA = aPrd.reduce(
        (previousValue, currentValue) => previousValue + currentValue.Quantity, 0
      )
      const bPrd = this.tempEventCart.filter(item => item.Type === 'B')
      const sumB = bPrd.reduce(
        (previousValue, currentValue) => previousValue + currentValue.Quantity, 0
      )
      return sumA === sumB
    },
    originSum () {
      const sum = this.originList.reduce(
        (previousValue, currentValue) => previousValue + currentValue.Quantity, 0
      )
      return sum
    }
  },
  mounted () {
    this.checkCookie()
    this.getCheckoutCart()
    // 是否從聯邦租車頁面來
    if (this.$route.path === '/event/unionrentalcar' || sessionStorage.getItem('store')) {
      this.isFromUnionCarRental = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/_checkoutcart';

.addon-error{
border:2px solid red;
 background-color: rgb(241, 196, 196);
}
</style>
